import { render, staticRenderFns } from "./zcsb.vue?vue&type=template&id=3c19fc15&scoped=true&"
import script from "./zcsb.vue?vue&type=script&lang=js&"
export * from "./zcsb.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./zcsb.vue?vue&type=style&index=0&id=3c19fc15&scoped=true&lang=scss&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "3c19fc15",
  "57d203f2"
  
)

export default component.exports