<template>
  <!-- 商标在线自主注册页 -->
  <div class="index">
    <!-- 头部组件 -->
    <Header />
    <!-- 商标头部搜索分栏 -->
    <ColumnSearch :buldword="2" />

    <div class="bodyBox">
      <img
        class="background"
        src="../imger/tranResg/tranResgback.png"
        alt="商标注册"
      />
      <div class="sousuo">
        <p class="sousuotishi">
          填写电话号码，专业商标专员帮您查询是否能注册。
        </p>
        <!-- 搜索栏加输入联系方式查询注册 -->
        <div class="input">
          <input
            class="tradclass"
            v-model="inputData01"
            placeholder="输入类别或者名称"
          />
          <div class="register">
            <input
              class="registerinput"
              v-model="inputData02"
              placeholder="输入您的联系方式"
            />
            <button class="registerbotton" @click="fn1()">
              免费查询能否注册
            </button>
          </div>
        </div>
      </div>
      <ServerTop
        title="在线自主注册商标"
        wera="问标网商标智能注册系统，收费一标一类低至380"
        jiage="380"
      />
      <div class="content">
        <div class="header">
          <div class="lan"></div>
          <p>服务详情</p>
        </div>
        <!-- 广告-商标注册介绍 -->
        <div class="AD">
          <div class="AD01">
            <div class="AD01box">
              <div class="AD01box01">超6000万商标大数据</div>
              <div class="AD01box02">手机扫码自主申报</div>
              <div class="AD01box03">
                <div class="picture"></div>
                <p>扫码体验小程序</p>
              </div>
              <div class="AD01box04">免费查询商标</div>
              <div class="AD01box05">专业客服在线服务</div>
            </div>
          </div>

          <div class="AD02">
            <div class="title">
              <img
                src="../imger/AR/title.png"
                alt="问标网"
                class="titletubiaoleft"
              />
              <p>为什么要注册商标</p>
              <img
                src="../imger/AR/title.png"
                alt="问标网"
                class="titletubiaoright"
              />
            </div>
            <div class="AD02content">
              <div class="box">
                <img
                  class="boxicon"
                  src="../imger/tranResg/tuceng03.png"
                  alt="市场运营通行证"
                />
                <div class="wear">
                  <p class="wear01">市场运营通行证</p>
                  <div class="boxlan"></div>
                  <p class="wear02">微博、微信认证、入驻天猫、京东等必要凭证</p>
                </div>
              </div>
              <div class="box">
                <img
                  class="boxicon"
                  src="../imger/tranResg/tuceng04.png"
                  alt="品牌保护盾牌"
                />
                <div class="wear">
                  <p class="wear01">品牌保护盾牌</p>
                  <div class="boxlan"></div>
                  <p class="wear02">
                    获得专属权，主动防御（防止品牌被他人恶意盗用）
                  </p>
                </div>
              </div>
              <div class="box">
                <img
                  class="boxicon"
                  src="../imger/tranResg/tuceng05.png"
                  alt="增加无形资产"
                />
                <div class="wear">
                  <p class="wear01">增加无形资产</p>
                  <div class="boxlan"></div>
                  <p class="wear02">
                    提高品牌市场估值、交易快、增值快，实现品牌价值
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="AD03">
            <div class="title">
              <img
                src="../imger/AR/title.png"
                alt="问标网"
                class="titletubiaoleft"
              />
              <p>商标注册无忧</p>
              <img
                src="../imger/AR/title.png"
                alt="问标网"
                class="titletubiaoright"
              />
            </div>
            <p class="AD03slogan">商标注册有障碍 问标网为您的品牌路保驾护航</p>
            <div class="AD03content">
              <div class="AD03box">
                <p class="AD03boxtitle">商标查询</p>
                <div class="lan"></div>
                <p class="AD03boxwear">
                  智能+人工，双重排查近似商标，有效提升商标注册成功率
                </p>
                <button
                  class="AD03botton"
                  @click="
                    linkDownload(
                      'https://www14c1.53kf.com/webCompany.php?arg=10248253&style=1&code_arg=b5d0f2c76a96fff81d61e378ffd7c74d9'
                    )
                  "
                >
                  立即办理
                </button>
              </div>
              <div class="AD03box">
                <p class="AD03boxtitle">材料准备</p>
                <div class="lan"></div>
                <p class="AD03boxwear">
                  企业：营业执照复印件个人：个体工商营业执照复印件/身份证复印件
                </p>
                <button
                  class="AD03botton"
                  @click="
                    linkDownload(
                      'https://www14c1.53kf.com/webCompany.php?arg=10248253&style=1&code_arg=b5d0f2c76a96fff81d61e378ffd7c74d9'
                    )
                  "
                >
                  立即办理
                </button>
              </div>
              <div class="AD03box">
                <p class="AD03boxtitle">材料审核</p>
                <div class="lan"></div>
                <p class="AD03boxwear">商标注册材料审核，准备商标注册委托书</p>
                <button
                  class="AD03botton"
                  @click="
                    linkDownload(
                      'https://www14c1.53kf.com/webCompany.php?arg=10248253&style=1&code_arg=b5d0f2c76a96fff81d61e378ffd7c74d9'
                    )
                  "
                >
                  立即办理
                </button>
              </div>
              <div class="AD03box">
                <p class="AD03boxtitle">商标申请书</p>
                <div class="lan"></div>
                <p class="AD03boxwear">只做符合商标局标准格式的商标申请书</p>
                <button
                  class="AD03botton"
                  @click="
                    linkDownload(
                      'https://www14c1.53kf.com/webCompany.php?arg=10248253&style=1&code_arg=b5d0f2c76a96fff81d61e378ffd7c74d9'
                    )
                  "
                >
                  立即办理
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="AD04">
        <ServerFoot :data="serverData" :show="true" />
        <ServerCl :show="true" Cl="" />
      </div>
    </div>

    <!-- 点击弹出之后的弹出框 -->
    <Drawer />
    <!-- 固定弹窗按钮 -->
    <Eject />
    <!-- sidebar组件 -->
    <Sidebar />
    <!-- 页面关于我们脚部组件 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../components/header.vue";
import Footer from "../components/footer.vue";
import Sidebar from "../components/sidebar.vue";
import Eject from "../components/eject.vue";
import Drawer from "../components/drawer.vue";
import ColumnSearch from "../components/columnSearch.vue";
import TradHeader from "../components/trademarkClassification/tradHeader.vue";

import Title from "../components/trademarkRegistration/title.vue";
import ServerTop from "../components/serverTop.vue";
import ServerFoot from "../components/serverFoot.vue";
import ServerCl from "../components/serverCl.vue";

import { message } from "../API/index";
export default {
  components: {
    Sidebar,
    Footer,
    Header,
    Drawer,
    Eject,
    ColumnSearch,
    TradHeader,
    Title,
    ServerTop,
    ServerFoot,
    ServerCl,
  },
  data() {
    return {
      input: "",
      serverData: [
        {
          title: "在线检索",
          content: "申请人在线自主查询检索商标成功率",
        },
        {
          title: "准备注册材料",
          content: "在线填写商标注册信息，自主准备申请人资质和打印委托书",
        },
        {
          title: "提交注册资料",
          content: "资料准备齐全后，在个人中心补充完善商标申请注册资料",
        },
        {
          title: "递交商标局",
          content: "平台人工审核注册资料，合规的提交国家商标局系统",
        },
        {
          title: "状态跟踪",
          content: "及时同步官方数据，注册进度在线及时可查",
        },
        {
          title: "获取证书",
          content: "商标注册通过后，统一派发商标注册证。 ",
        },
      ],
      inputData01: "",
      inputData02: "",
      userID: "",
      userkey: "",
      messagedata: {
        user_nickname: "",
        user_name: "",
        user_phone: "",
        user_id: 0,
        message: "",
        source: "商标在线注册",
        advisories: "",
        reg_id: "",
        reg_type: "",
        platform: "问标",
        brand_num: "",
      },
    };
  },
  methods: {
    // 新窗口打开客服咨询
    linkDownload(url) {
      window.open(url, "_blank");
    },
    async fn1() {
      if (this.inputData02 == "" && this.inputData01 == "") {
        this.$message({
          showClose: true,
          message: "请输入信息，获取优质服务",
        });
      } else {
        if (sessionStorage.getItem("userkey")) {
          this.userID = JSON.parse(localStorage.getItem("user"));
          this.userkey = sessionStorage.getItem("userkey");
          this.messagedata.user_id = this.userID.id;
          this.messagedata.user_phone = this.userID.phone;
          this.messagedata.user_nickname = this.userID.name;
        } else {
          this.userkey = sessionStorage.getItem("key");
        }
        this.messagedata.message = this.inputData01;
        const res = await message({
          key: this.userkey,
          phone: this.inputData02,
          user_id: this.userID == "" ? 0 : this.userID.id,
          message: JSON.parse(JSON.stringify(this.messagedata)),
        });
        if (res.data.key) {
          this.$message({
            type: "success",
            message: "信息提交成功，等待专员联系",
          });
          this.inputData02 = "";
          this.inputData01 = "";
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.index {
  width: 100%;
  height: 100%;
  .bodyBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .background {
      width: 100%;
      height: 29.6875rem /* 475px -> 29.6875rem */;
    }
    .sousuo {
      position: absolute;
      top: 13.125rem /* 210px -> 13.125rem */;
      left: 50%;
      transform: translateX(-50%);
      width: 72rem /* 1152px -> 72rem */;
      height: 8.875rem /* 142px -> 8.875rem */;
      margin-top: 2.0625rem /* 33px -> 2.0625rem */;
      background: rgba($color: #fff, $alpha: 0.4);
      border-radius: 0.625rem /* 10px -> 0.625rem */;
      padding: 1.6875rem /* 27px -> 1.6875rem */;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .sousuotishi {
        font-size: 0.875rem /* 14px -> 0.875rem */;
        color: #fff;
      }
      .input {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .tradclass {
          width: 21.9375rem /* 351px -> 21.9375rem */;
          height: 3.4375rem /* 55px -> 3.4375rem */;
          border: none;
          border-radius: 0.3125rem /* 5px -> 0.3125rem */;
          font-size: 1.125rem /* 18px -> 1.125rem */;
          padding: 1.25rem /* 20px -> 1.25rem */;
          box-sizing: border-box;
          outline: none;
        }
        .register {
          height: 3.4375rem /* 55px -> 3.4375rem */;
          display: flex;
          .registerinput {
            width: 30.8125rem /* 493px -> 30.8125rem */;
            height: 100%;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            border: none;
            font-size: 1.125rem /* 18px -> 1.125rem */;
            padding: 1.25rem /* 20px -> 1.25rem */;
            box-sizing: border-box;
            outline: none;
          }
          .registerbotton:hover {
            font-size: 1.25rem /* 20px -> 1.25rem */;
          }
          .registerbotton {
            border: none;
            outline: none;
            width: 13.78125rem /* 220.5px -> 13.78125rem */;
            height: 3.4375rem /* 55px -> 3.4375rem */;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            background-color: #ff840c;
            font-size: 1.125rem /* 18px -> 1.125rem */;
            color: #fff;
            cursor: pointer;
            transition-duration: 0.1s;
          }
        }
      }
    }
    .content {
      width: 75rem /* 1200px -> 75rem */;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        width: 100%;
        font-size: 1.5rem /* 24px -> 1.5rem */;
        color: #333333;
        display: flex;
        justify-content: center;
        align-items: center;
        .titletubiaoleft {
          width: 1.625rem /* 26px -> 1.625rem */;
          height: 100%;
          margin-right: 1.25rem /* 20px -> 1.25rem */;
        }
        .titletubiaoright {
          width: 1.625rem /* 26px -> 1.625rem */;
          height: 100%;
          margin-left: 1.25rem /* 20px -> 1.25rem */;
        }
      }
      .header {
        width: 100%;
        height: 3.125rem /* 50px -> 3.125rem */;
        padding: 0.625rem /* 10px -> 0.625rem */;
        box-sizing: border-box;
        font-size: 1.25rem /* 20px -> 1.25rem */;
        color: #333333;
        background-color: #f7f7f7;
        display: flex;
        align-items: center;
        .lan {
          width: 0.3125rem /* 5px -> 0.3125rem */;
          height: 1.6875rem /* 27px -> 1.6875rem */;
          margin-right: 0.875rem /* 14px -> 0.875rem */;
          background-color: #4d89f1;
        }
      }
      .AD {
        width: 100%;
        margin-top: 2.125rem /* 34px -> 2.125rem */;
        .AD01 {
          width: 100%;
          height: 42.875rem /* 686px -> 42.875rem */;
          background: #fff;
          background-image: url("../imger/tranResg/bg1.jpg");
          background-size: 100% 100%;
          border-radius: 10px;
          .AD01box {
            width: 100%;
            height: 15.625rem /* 250px -> 15.625rem */;
            position: relative;
            .AD01box01 {
              width: 12.1875rem /* 195px -> 12.1875rem */;
              height: 3.9375rem /* 63px -> 3.9375rem */;
              background-image: url("../imger/tranResg/1.png");
              position: absolute;
              top: 3.875rem /* 62px -> 3.875rem */;
              left: 18.125rem /* 290px -> 18.125rem */;
              background-size: 100% 100%;
              font-size: 1rem /* 16px -> 1rem */;
              color: #fff;
              text-align: center;
              line-height: 2.6875rem /* 43px -> 2.6875rem */;
            }
            .AD01box02 {
              width: 9.6875rem /* 155px -> 9.6875rem */;
              height: 3.9375rem /* 63px -> 3.9375rem */;
              background-image: url("../imger/tranResg/group01.png");
              position: absolute;
              top: 10.1875rem /* 163px -> 10.1875rem */;
              left: 22.5625rem /* 361px -> 22.5625rem */;
              background-size: 100% 100%;
              font-size: 1rem /* 16px -> 1rem */;
              color: #4d89f1;
              text-align: center;
              line-height: 2.6875rem /* 43px -> 2.6875rem */;
            }
            .AD01box03 {
              height: 9.6875rem /* 155px -> 9.6875rem */;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              position: absolute;
              top: 2.6875rem /* 43px -> 2.6875rem */;
              left: 33.9375rem /* 543px -> 33.9375rem */;
              font-size: 1rem /* 16px -> 1rem */;
              color: #333333;
              .picture {
                width: 7.9375rem /* 127px -> 7.9375rem */;
                height: 7.9375rem /* 127px -> 7.9375rem */;
                background-image: url("../imger/tranResg/xcx01.png");
                background-size: 100% 100%;
              }
            }
            .AD01box04 {
              width: 9.6875rem /* 155px -> 9.6875rem */;
              height: 3.9375rem /* 63px -> 3.9375rem */;
              background-image: url("../imger/tranResg/group01.png");
              position: absolute;
              top: 3.5rem /* 56px -> 3.5rem */;
              right: 19.125rem /* 306px -> 19.125rem */;
              background-size: 100% 100%;
              font-size: 1rem /* 16px -> 1rem */;
              color: #4d89f1;
              text-align: center;
              line-height: 2.6875rem /* 43px -> 2.6875rem */;
            }
            .AD01box05 {
              width: 9.6875rem /* 155px -> 9.6875rem */;
              height: 3.9375rem /* 63px -> 3.9375rem */;
              background-image: url("../imger/tranResg/group03.png");
              position: absolute;
              top: 10.125rem /* 162px -> 10.125rem */;
              right: 20.0625rem /* 321px -> 20.0625rem */;
              background-size: 100% 100%;
              font-size: 1rem /* 16px -> 1rem */;
              color: #fff;
              text-align: center;
              line-height: 2.6875rem /* 43px -> 2.6875rem */;
            }
          }
        }
        .AD02 {
          height: 20.3125rem /* 325px -> 20.3125rem */;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          .AD02content {
            height: 10rem /* 160px -> 10rem */;
            width: 100%;
            display: flex;
            justify-content: space-between;
            .box {
              height: 100%;
              width: 24rem /* 384px -> 24rem */;
              display: flex;
              justify-content: space-around;
              box-shadow: 0 2px 5px 2px rgba(112, 112, 112, 0.4);
              align-items: center;
              .boxicon {
                width: 6.875rem /* 110px -> 6.875rem */;
                height: 8.0625rem /* 129px -> 8.0625rem */;
              }
              .wear {
                width: 11.875rem /* 190px -> 11.875rem */;
                height: 8.0625rem /* 129px -> 8.0625rem */;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                .wear01 {
                  font-size: 1.375rem /* 22px -> 1.375rem */;
                  color: #02136d;
                }
                .boxlan {
                  width: 2.625rem /* 42px -> 2.625rem */;
                  height: 0.3125rem /* 5px -> 0.3125rem */;
                  background-color: #ff840c;
                }
                .wear02 {
                  font-size: 0.875rem /* 14px -> 0.875rem */;
                  color: #555555;
                }
              }
            }
          }
        }
        .AD03 {
          width: 100%;
          height: 29.75rem /* 476px -> 29.75rem */;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          .AD03slogan {
            font-size: 1.125rem /* 18px -> 1.125rem */;
            color: #666666;
          }
          .AD03content {
            width: 100%;
            height: 15.625rem /* 250px -> 15.625rem */;
            display: flex;
            justify-content: space-between;
            .AD03box {
              width: 16.6875rem /* 267px -> 16.6875rem */;
              height: 100%;
              box-shadow: 0 2px 5px 2px rgba(112, 112, 112, 0.4);
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
              align-items: center;
              .AD03boxtitle {
                font-size: 1.375rem /* 22px -> 1.375rem */;
                color: #333333;
              }
              .AD03boxwear {
                width: 11.5rem /* 184px -> 11.5rem */;
                font-size: 1rem /* 16px -> 1rem */;
                color: #666666;
              }
              .lan {
                width: 2.8125rem /* 45px -> 2.8125rem */;
                height: 0.1875rem /* 3px -> 0.1875rem */;
                background-color: #ececed;
              }
              .AD03botton {
                width: 8.5rem /* 136px -> 8.5rem */;
                height: 2.5625rem /* 41px -> 2.5625rem */;
                border-radius: 1.2813rem /* 20.5px -> 1.2813rem */;
                color: #fff;
                background-color: #4d89f1;
                line-height: 2.5625rem /* 41px -> 2.5625rem */;
                text-align: center;
                border: none;
                cursor: pointer;
                font-size: 1.125rem /* 18px -> 1.125rem */;
                transition-duration: .1s;
              }
              .AD03botton:hover{
                font-size: 1.25rem /* 20px -> 1.25rem */;
              }
            }
          }
        }
      }
    }
    .AD04 {
      width: 75rem /* 1200px -> 75rem */;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      .AD03slogan {
        font-size: 1.125rem /* 18px -> 1.125rem */;
        color: #666666;
      }
      .process {
        width: 75rem /* 1200px -> 75rem */;
        height: 15.625rem /* 250px -> 15.625rem */;
        display: flex;

        .numberbox {
          width: 8.375rem /* 134px -> 8.375rem */;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          .tubiao {
            width: 100%;
            height: 8.375rem /* 134px -> 8.375rem */;
          }
          .numberboxwear01 {
            font-size: 1.375rem /* 22px -> 1.375rem */;
            font-weight: bold;
            color: #333333;
          }
          .numberboxwear02 {
            font-size: 1rem /* 16px -> 1rem */;
            color: #666666;
          }
        }
        .num {
          height: 1.875rem /* 30px -> 1.875rem */;
          width: 5.0625rem /* 81px -> 5.0625rem */;
          margin-top: 3.9375rem /* 63px -> 3.9375rem */;
          position: relative;
          .lan {
            position: absolute;
            top: 0.9375rem /* 15px -> 0.9375rem */;
            width: 5.0625rem /* 81px -> 5.0625rem */;
            height: 0.125rem /* 2px -> 0.125rem */;
            background-color: #fcc515;
          }
          .number {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            height: 1.875rem /* 30px -> 1.875rem */;
            width: 1.875rem /* 30px -> 1.875rem */;
            border-radius: 50%;
            text-align: center;
            line-height: 1.875rem /* 30px -> 1.875rem */;
            background-color: #fcc515;
            color: #fff;
          }
        }
      }
      .Science {
        width: 75rem /* 1200px -> 75rem */;
        height: 20rem /* 320px -> 20rem */;
        display: flex;
        .scienceLeft {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .Science01 {
            font-size: 2.125rem /* 34px -> 2.125rem */;
            font-weight: bold;
            color: #333333;
          }
          .Science02 {
            font-size: 1rem /* 16px -> 1rem */;
            color: #666666;
          }
          .scienceLeftBox {
            height: 10rem /* 160px -> 10rem */;
            width: 41.0625rem /* 657px -> 41.0625rem */;
            display: flex;
            justify-content: space-between;
            .box {
              width: 18.75rem /* 300px -> 18.75rem */;
              height: 10rem /* 160px -> 10rem */;
              box-shadow: 0 2px 5px 2px rgba(112, 112, 112, 0.4);
              background-color: #fff;
              display: flex;
              justify-content: space-around;
              align-items: center;
              .boxtubiao {
                width: 7.5rem /* 120px -> 7.5rem */;
                height: 7.5rem /* 120px -> 7.5rem */;
              }
              .boxwear {
                width: 8.125rem /* 130px -> 8.125rem */;
                height: 7.5rem /* 120px -> 7.5rem */;
                .boxweartitle {
                  font-size: 1rem /* 16px -> 1rem */;
                  color: #fcc515;
                  line-height: 2.5rem /* 40px -> 2.5rem */;
                }
                .boxwearlei {
                  font-size: 0.875rem /* 14px -> 0.875rem */;
                  color: #666666;
                  line-height: 1.25rem /* 20px -> 1.25rem */;
                }
              }
            }
          }
        }
        .scienceRight {
          width: 27.1875rem /* 435px -> 27.1875rem */;
          height: 20rem /* 320px -> 20rem */;
        }
      }
    }
  }
}
</style>